import {
  handleFormattedStatisticsQuery,
  handleDownloadBlob,
} from "../../../utils";
import { useCallback, useEffect } from "react";
import { statisticsAPI } from "../../../services";
import { useMapBoxParams } from "../../../store/useMapBoxParams";
import { useDownloadParams } from "../../../store";
import { useNotification } from "../../../hooks";
import { getErrorAPIMessage } from "../../../errors/getErrorAPIMessage";
import { handleFormattedStatisticsMapResponse } from "../../../utils/handleFormattedStatisticsMapResponse";
import useHumanize from "../../../humanize";
import { api } from "../../../services/axios";

function useStatisticsV2({
  selection,
  isDownload = false,
  yearsLoading = false,
  demographicsLoading = false,
}) {
  const { handleNotify } = useNotification();
  const humanize = useHumanize();

  const { statistics, setStatistics, selectedYear, selectedDemographic } =
    useMapBoxParams(
      ({ statistics, setStatistics, selectedYear, selectedDemographic }) => ({
        statistics,
        setStatistics,
        selectedYear,
        selectedDemographic,
      })
    );

  const { isLoadingCSV, setIsLoadingCSV } = useDownloadParams(
    ({ isLoadingCSV, setIsLoadingCSV }) => ({
      isLoadingCSV,
      setIsLoadingCSV,
    })
  );

  const handleFetchStatistics = useCallback(
    async ({ listURL, groupBy = "data" }) => {
      const responses = await Promise.allSettled(
        listURL.map((url) => statisticsAPI.get(url))
      );

      const resultData = responses.reduce((acc, response) => {
        if (response.status === "fulfilled") {
          return acc.concat(
            groupBy === "data"
              ? response?.value?.data
              : response?.value?.config?.url || []
          );
        } else {
          return acc;
        }
      }, []);

      const rejectedResponses = responses.filter(
        (response) => response.status === "rejected"
      );

      return { resultData, rejectedResponses };
    },
    []
  );

  const handleFetchStatisticsModalDownload = useCallback(
    async ({ selection }) => {

      let data = JSON.stringify({
        category: [selection?.category],
        level: [selection?.level],
        measure: selection?.measure,
        year: selection?.year,
        state: selection?.countyFilter,
      });

      try {
        const response = await api.post("/cube/download", data, {
          responseType: "blob",
          headers: {
            "Content-Type": "application/json",
          },
        });

        return response;
      } catch (error) {
        console.log("error", error);
        handleNotify({
          type: "error",
          content: getErrorAPIMessage(error),
        });
      }
    },
    [handleNotify]
  );

  const postUserMetrics = useCallback(
    async ({ metricName, query = "path to the file in s3" }) => {
      try {
        const data = {
          metric_name: metricName,
          query: query,
        };
        await api.post("/metrics/", data);
      } catch (error) {
        console.log("error", error);
        handleNotify({
          type: "error",
          content: getErrorAPIMessage(error),
        });
      }
    },
    [handleNotify]
  );

  const handleGetMapStatistics = useCallback(async () => {
    setStatistics({
      isLoading: true,
      data: [],
      demographics: {},
    });

    const { listURL } = handleFormattedStatisticsQuery({
      category: selection?.category,
      level: selection?.level,
      statistic: selection?.statistic,
      unit: selection?.unit,
      countyFilter: selection?.countyFilter,
      selectedYear,
      yearsLoading,
      selectedDemographic,
      demographicsLoading,
    });

    if (!listURL?.length) return;

    try {
      const { resultData } = await handleFetchStatistics({ listURL });

      if (resultData.length > 0) {
        const { data } = await handleFormattedStatisticsMapResponse({
          resultData,
          category: selection?.category,
        });

        setStatistics({
          isLoading: false,
          data,
        });
      }
    } catch (error) {
      setStatistics({
        isLoading: false,
        data: [],
      });
    }
  }, [
    setStatistics,
    selection?.level,
    selection?.statistic,
    selection?.unit,
    selection?.countyFilter,
    selection?.category,
    selectedYear,
    yearsLoading,
    selectedDemographic,
    demographicsLoading,
    handleFetchStatistics,
  ]);

  const handleGetDownloadStatistics = useCallback(async () => {

    try {
      setIsLoadingCSV(true);

      const response = await handleFetchStatisticsModalDownload({
        selection,
      });

      if (response?.data) {
        const filename = "statistics.zip";
        await handleDownloadBlob(response.data, filename);

        postUserMetrics({
          metricName: selection?.measure.join(","),
          query: "path to the file in s3",
        });

        handleNotify({
          type: "success",
          content: `Successfully downloaded data`,
        });
      } else {
        handleNotify({
          type: "error",
          content: `No data found`,
        });
      }
    } catch (error) {
      handleNotify({
        type: "error",
        content: getErrorAPIMessage(error),
      });
    } finally {
      setIsLoadingCSV(false);
    }
  }, [
    demographicsLoading,
    handleFetchStatistics,
    handleNotify,
    isDownload,
    selectedDemographic,
    selection?.countyFilter,
    selection?.level,
    selection?.selectedYears,
    selection?.statistic,
    selection?.unit,
    selection?.category,
    setIsLoadingCSV,
    yearsLoading,
    postUserMetrics,
    humanize,
  ]);

  useEffect(() => {
    !isDownload && handleGetMapStatistics();
  }, [handleGetMapStatistics, isDownload]);

  return {
    statistics,
    selectedYear,
    isLoadingCSV,
    handleGetDownloadStatistics,
  };
}

export default useStatisticsV2;

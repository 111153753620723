import React, { useEffect, useState } from "react";
import { Modal, Button, Radio, List } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import _remove from "lodash/fp/remove";
import { useLocation } from "react-router-dom";
import { useMeasures } from "../../cube";
import useHumanize from "../../humanize";
import { getCategories } from "../../pages/StatisticPage/StatisticsMenu";
import { useLevels } from "../../pages/StatisticPage/selection";
import {
  useGetFullYears,
  useStatisticsV2,
} from "../../pages/StatisticPage/query";
import { demographics } from "./demographics";
import "./Modal.css";
import { REMOVED_STATISTICS, STATES } from "../../constants";
import colors from "../../colors";
import { useMapBoxParams } from "../../store";

function DownloadModal({ visible, setVisible, handleClickPrivateMenu }) {
  const geographyTypes = ["tract", "block_group"];

  const dictionaryDownloadUrl = !!process.env.PUBLIC_URL.length
    ? `${process.env.PUBLIC_URL.length}/DataDictionary.xlsx`
    : "/DataDictionary.xlsx";

  const measures = useMeasures();

  let filteredCategories = getCategories(
    _remove((measure) => measure.category === null)(measures)
  );

  const humanize = useHumanize();
  const location = useLocation();
  const { countyFilter, selectedYear, level } = useMapBoxParams(
    ({ countyFilter, selectedYear, level }) => ({
      countyFilter,
      selectedYear,
      level,
    })
  );

  const [listData, setListData] = useState(
    formatListData(filteredCategories, selectedYear)
  );
  const [selectedCategory, setSelectedCategory] = useState("Economy");
  const [selectedGeography, setSelectedGeography] = useState(level);
  const [selectedStates, setSelectedStates] = useState([]);
  const [selectedYears, setSelectedYears] = useState([]);
  const [selectedDemographic, setSelectedDemographic] = useState({
    type: "age",
    value: "18_to_29",
  });
  const [selectedStatistics, setSelectedStatistics] = useState([]);
  const [yearOptions, setYearOptions] = useState([]);

  useEffect(() => {
    if (!listData.hasOwnProperty("categories")) {
      setListData(formatListData(filteredCategories));
    }
  }, [listData, filteredCategories]);

  useEffect(() => {
    // If user has made a selection on the map, open the modal with their selection preselected in the form.
    const category = parseUrl(location, 2);
    const statistic = parseUrl(location, 3);
    const preSelectedStatistic =
      listData[category] &&
      listData[category].statistics
        .map((s) => s.options.filter((o) => o.statistic === statistic))
        .filter((r) => r.length && r[0])[0];

    setSelectedCategory(category || "economy");

    if (statistic) {
      setSelectedStates(countyFilter);
      setSelectedStatistics(preSelectedStatistic);
    }
  }, [countyFilter, listData, location, visible]);

  const { listYears: years, isLoading: yearsLoading } = useGetFullYears({
    statistic: selectedStatistics?.at(-1)?.statistic,
    unit: selectedStatistics?.at(-1)?.unit,
    level: selectedGeography,
  });

  const { isLoadingCSV, handleGetDownloadStatistics } = useStatisticsV2({
    selection:
      {
        category: selectedCategory,
        measure: selectedStatistics?.map((s) => s.statistic+":"+s.unit),
        year: selectedYears,
        level: selectedGeography,
        primaryDemographic:
          selectedCategory === "health" ? selectedDemographic : null,
        countyFilter: selectedStates,
      } || {},
    isDownload: true,
    yearsLoading,
  });

  useEffect(() => {
    if (years && yearOptions.toString() !== years.toString()) {
      setYearOptions(years);
    }
  }, [yearOptions, years]);

  const handleCancel = () => {
    setVisible(!visible);
    setSelectedStatistics([]);
    setSelectedCategory("economy");
  };

  const handleSelectCategory = (item) => {
    if (selectedCategory !== item && !isCategoryDisabled(item)) {
      setSelectedCategory(item);
      setSelectedStatistics([]);
      // setYearOptions([]);
    }
  };

  const handleSelectDemographics = (demographic) => {
    setSelectedDemographic(demographic);
    if (isDemographicDisabled()) setSelectedDemographic(null);
  };

  const handleSelectGeography = (item) => {
    if (!selectedGeography.includes(item)) {
      setSelectedGeography(item);
      setSelectedStatistics([]);
    }
  };

  const handleSelectStatistic = (item) => {
    const exists = selectedStatistics.some(
      (m) => m.statistic === item.statistic
    );

    if (isStatisticEnabled(item)) {
      setSelectedStatistics((prevState) => {
        if (exists) {
          return prevState.filter((x) => x.statistic !== item.statistic);
        }
        return exists ? prevState : [...prevState, item];
      });
    }
  };

  const handleSelectedYears = (year) => {
    setSelectedYears((prevYears) => {
      if (prevYears.includes(year)) {
        return prevYears.filter((y) => y !== year);
      } else {
        return [...prevYears, year];
      }
    });
  };

  const handleSelectedState = (state) => {
    setSelectedStates((prevSelectedStates) => {
      if (prevSelectedStates.includes(state)) {
        return prevSelectedStates.filter((s) => s !== state);
      } else {
        return [...prevSelectedStates, state];
      }
    });
  };

  const isCategoryDisabled = (category) =>
    !listData[category].levels.some((i) =>
      i.level.some((x) => x === selectedGeography)
    );

  const isDemographicDisabled = () =>
    selectedCategory !== "health" ||
    !selectedStatistics.some((m) => m.category === "health");

  const isStatisticEnabled = (item) =>
    listData[selectedCategory]?.levels.some(
      (l) =>
        l.statistic === item.statistic && l.level.includes(selectedGeography)
    );

  const renderYearsData = () => {
    if (
      !selectedStatistics.some((s) => s.category === selectedCategory) ||
      yearsLoading ||
      !yearOptions
    ) {
      return [];
    }
    return yearOptions;
  };

  const renderSelectedYearsOnStatistic = (item) => {
    const currentSelection = selectedStatistics.filter(
      (s) => s.statistic === item.statistic
    )[0];

    const isSelected = selectedStatistics.some(
      (s) => s.statistic === item.statistic
    );

    if (isSelected && !currentSelection.selectedYears.length) {
      return (
        <span style={{ color: "red", fontSize: 12 }}>
          Select at least one year
        </span>
      );
    } else if (isSelected && currentSelection.selectedYears.length) {
      return currentSelection.selectedYears.sort().join(", ");
    } else {
      return null;
    }
  };

  return (
    <Modal
      title={
        <div key="title" style={{ color: "white", paddingTop: "16px" }}>
          <FormattedMessage
            description="Export selected data"
            defaultMessage="Export Data"
          />
        </div>
      }
      open={visible}
      onCancel={handleCancel}
      width={1080}
      bodyStyle={{ padding: 0 }}
      closeIcon={<CloseOutlined style={{ color: colors.white }} />}
      centered
      className="modal-style"
      footer={[
        <div
          key="selection is required"
          style={{ color: "#FFD0D0", float: "left", fontSize: 12 }}
        >
          *
          <FormattedMessage
            description="Selection is required"
            defaultMessage="At least one selection is required"
          />
        </div>,
        <Button
          key="Download Data Dictionary"
          type="link"
          download
          href={dictionaryDownloadUrl}
          style={{
            margin: "0 10px 4px 0",
            color: colors.yellowLogin,
          }}
        >
          <FormattedMessage
            description="Download Data Dictionary"
            defaultMessage="Download Data Dictionary"
          />
        </Button>,

        <Button
          type="primary"
          style={{
            borderRadius: "30px",
            border: "1px solid #2A61B4",
            backgroundColor: "#2758A2",
          }}
          loading={isLoadingCSV}
          onClick={() => {
            if (handleClickPrivateMenu("download")) {
              handleGetDownloadStatistics();
            }
          }}
          disabled={
            !selectedStatistics.length ||
            (selectedStatistics &&
              !selectedYear.length) ||
            isLoadingCSV ||
            !selectedStates?.length
          }
        >
          <FormattedMessage
            description="Download Data"
            defaultMessage="Download Data"
          />
        </Button>,
      ]}
    >
      <div className="list-wrapper">
        <List
          size="small"
          header={
            <FormattedMessage
              description="Selected geography type"
              defaultMessage="Geography Type"
            />
          }
          bordered
          dataSource={geographyTypes}
          className="list"
          style={{
            width: "25%",
            borderRadius: "4px 0 0 4px",
          }}
          renderItem={(item) => (
            <List.Item
              style={{
                backgroundColor:
                  selectedGeography.includes(item) && "rgba(0, 82, 155, 0.40)",
                fontSize: 12,
                color: "#fff",
                borderBottom: "1px solid #054479",
              }}
              onClick={() => handleSelectGeography(item)}
              actions={[
                <Radio
                  checked={selectedGeography.includes(item)}
                  onChange={() => handleSelectGeography(item)}
                  style={{
                    fontSize: 12,
                    margin: 0,
                  }}
                />,
              ]}
            >
              {humanize(item)}
            </List.Item>
          )}
        />
        <List
          size="small"
          header={
            <FormattedMessage description="Domain" defaultMessage="Domain" />
          }
          bordered
          dataSource={listData.categories}
          className="list"
          renderItem={(item) => (
            <List.Item
              style={{
                backgroundColor:
                  selectedCategory === item &&
                  !isCategoryDisabled(item) &&
                  "rgba(0, 82, 155, 0.40)",
                fontSize: 12,
                color: isCategoryDisabled(item)
                  ? "rgba(255, 255, 255, 0.20)"
                  : "#fff",
                padding: "11.15px 16px",
                borderBottom: "1px solid #054479",
              }}
              onClick={() => handleSelectCategory(item)}
            >
              <span>
                {humanize(item)}
                {/* {selectedStatistics.some((s) => s.category === item) && (
                  <span
                    style={{
                      color: "#0577B1",
                      marginLeft: 10,
                      fontWeight: 500,
                    }}
                  >
                    {
                      selectedStatistics.filter((s) => s.category === item)
                        .length
                    }
                  </span>
                )} */}
              </span>
            </List.Item>
          )}
        />
        <List
          size="small"
          header={
            <FormattedMessage description="State" defaultMessage="State" />
          }
          bordered
          dataSource={STATES}
          style={{
            width: "20%",
            borderRadius: "4px 0 0 4px",
          }}
          className="list"
          renderItem={(item) => (
            <List.Item
              style={{
                backgroundColor:
                  selectedStates?.includes(item.zipcode) &&
                  "rgba(0, 82, 155, 0.40)",
                fontSize: 12,
                color: "#fff",
                padding: "11.15px 16px",
                borderBottom: "1px solid #054479",
              }}
              onClick={() => handleSelectedState(item.zipcode)}
            >
              <span>{item.name}</span>
            </List.Item>
          )}
        />

        <List
          size="small"
          header={
            <>
              <FormattedMessage
                description="Statistic"
                defaultMessage="Statistic"
              />
              <span style={{ color: "red", paddingLeft: 4 }}>*</span>
            </>
          }
          bordered
          dataSource={
            listData[selectedCategory]
              ? listData[selectedCategory].statistics
              : []
          }
          className="list"
          renderItem={(item, index) => {
            const humanizedStatisticGroup =
              item.subgroup !== "null" && humanize(item?.subgroup);

            if (REMOVED_STATISTICS.includes(humanizedStatisticGroup)) return;

            return (
              <span key={item.subgroup}>
                {item.subgroup !== "null" && (
                  <div
                    className="list-subgroup"
                    style={{
                      color:
                        item.subgroup === "bb" &&
                        selectedGeography === "block_group"
                          ? "rgba(0, 0, 0, 0.1)"
                          : "#91E9E4",
                      borderTop: index !== 0 && "1px solid rgba(0, 0, 0, 0.3)",
                    }}
                  >
                    {humanizedStatisticGroup}
                  </div>
                )}
                {item.options.map((i) => {
                  const humanizedStatistic = humanize(i.statistic);

                  if (REMOVED_STATISTICS.includes(humanizedStatistic))
                    return null;

                  return (
                    <List.Item
                      key={i.statistic}
                      style={{
                        border: item.subgroup !== "null" && 0,
                        color: !isStatisticEnabled(i)
                          ? "rgba(255, 255, 255, 0.20)"
                          : "#fff",
                        fontSize: 12,
                        backgroundColor:
                          selectedStatistics &&
                          selectedStatistics.some(
                            (s) => s.statistic === i.statistic
                          ) &&
                          "rgba(0, 82, 155, 0.40)",
                        borderBottom: "1px solid #054479",
                      }}
                      onClick={() => handleSelectStatistic(i)}
                      disabled={!isStatisticEnabled(i)}

                      // actions={[
                      //   <div>
                      //     <Checkbox
                      //       style={{ fontSize: 14 }}
                      //       checked={
                      //         selectedStatistics.some(
                      //           (m) => m.statistic === i.statistic
                      //         ) && isStatisticEnabled(i)
                      //       }
                      //       disabled={!isStatisticEnabled(i)}
                      //       onChange={() => {
                      //         handleSelectStatistic(i);
                      //       }}
                      //     />
                      //   </div>,
                      // ]}
                    >
                      <List.Item.Meta
                        title={
                          <span
                            style={{
                              color: !isStatisticEnabled(i)
                                ? "rgba(255, 255, 255, 0.20)"
                                : "#fff",
                            }}
                          >
                            {humanizedStatistic}
                          </span>
                        }
                        // description={
                        //   <div style={{ fontSize: 12 }}>
                        //     {renderSelectedYearsOnStatistic(i)}
                        //   </div>
                        // }
                        onClick={() => {
                          // isStatisticEnabled(i) &&
                          //   handleSelectStatistic(i, true);
                        }}
                      />
                    </List.Item>
                  );
                })}
              </span>
            );
          }}
        />

        <List
          size="small"
          header={
            <>
              <FormattedMessage description="Years" defaultMessage="Years" />{" "}
              <span style={{ color: "red" }}>*</span>
            </>
          }
          bordered
          dataSource={yearOptions}
          loading={yearsLoading}
          className="list"
          style={{ width: "18.5%" }}
          renderItem={(item) => (
            <List.Item
              style={{
                fontSize: 12,
                color: "#fff",
                backgroundColor: selectedYears.includes(item)
                  ? "rgba(0, 82, 155, 0.40)"
                  : undefined,
                borderBottom: "1px solid #054479",
              }}
              onClick={() => handleSelectedYears(item)}
            >
              {item}
            </List.Item>
          )}
        />
        <List
          size="small"
          header={
            <FormattedMessage
              description="Demographics"
              defaultMessage="Demographics"
            />
          }
          bordered
          dataSource={demographics.statistics}
          className="list"
          style={{ borderRadius: "0 4px 4px 0", width: "23%" }}
          renderItem={(item, index) => (
            <span key={item.subgroup}>
              {item.subgroup !== "null" && (
                <div
                  className="list-subgroup"
                  style={{
                    color: isDemographicDisabled()
                      ? "rgba(255, 255, 255, 0.20)"
                      : "#fff",
                    borderTop: index !== 0 && "1px solid #054479",
                  }}
                >
                  {humanize(item.subgroup)}
                </div>
              )}
              {item.options.map((i) => (
                <List.Item
                  key={i}
                  style={{
                    fontSize: 12,
                    color:
                      isDemographicDisabled() ||
                      (selectedStatistics.at(-1).statistic.includes("lbw") &&
                        item.subgroup === "sex")
                        ? "rgba(255, 255, 255, 0.20)"
                        : "#fff",
                    border: item.subgroup && 0,
                  }}
                  actions={[
                    <Radio
                      checked={
                        selectedDemographic &&
                        selectedDemographic.value === i &&
                        !isDemographicDisabled() &&
                        !(
                          selectedStatistics.at(-1).statistic.includes("lbw") &&
                          item.subgroup === "sex"
                        )
                      }
                      disabled={
                        isDemographicDisabled() ||
                        (selectedStatistics.at(-1).statistic.includes("lbw") &&
                          item.subgroup === "sex")
                      }
                      onChange={() =>
                        handleSelectDemographics({
                          type: item.subgroup,
                          value: i,
                        })
                      }
                      style={{
                        fontSize: 12,
                        margin: 0,
                      }}
                    />,
                  ]}
                >
                  {humanize(i)}
                </List.Item>
              ))}
            </span>
          )}
        />
      </div>
    </Modal>
  );
}

const parseUrl = (location, index) => {
  return (
    location.pathname.split("/")[index] &&
    location.pathname.split("/")[index].replaceAll("-", "_")
  );
};

const formatListData = (measures, selectedYear) => {
  let listData = {};
  Object.entries(measures).forEach(([key, value]) => {
    if (!listData.categories) listData.categories = [];
    if (!listData[key] && key !== "null") listData[key] = {};
    if (!listData[key].statistics) listData[key].statistics = [];
    if (!listData[key].levels) listData[key].levels = [];
    if (!listData.categories.includes(key)) listData.categories.push(key);
    Object.entries(value).forEach(([k, v]) => {
      Object.entries(v).forEach(([o, i]) => {
        listData[i.category].levels = [
          ...listData[i.category].levels,
          {
            level: useLevels(i),
            statistic: i.statistic,
          },
        ];
        if (
          !listData[i.category].statistics.some(
            (s) =>
              s.subgroup === i.title.substring(0, i.title.indexOf("_")) ||
              s.subgroup === k
          )
        ) {
          listData[i.category].statistics.push({
            subgroup: k,
            options: Object.values(v).map((o) => {
              return {
                ...o,
                selectedYears: selectedYear?.length ? selectedYear : [],
              };
            }),
          });
        }
      });
    });
  });
  return listData;
};

export const DownloadCsvModal = React.memo(DownloadModal);
